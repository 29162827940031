/* Vendor imports */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FaDiscord, FaSteam, FaReddit } from "react-icons/fa";
/* App imports */
import style from "./tag-list.module.less";

const tagToIcon = [
  {
    predicate: tag => tag.toLowerCase().includes("steam"),
    Icon: FaSteam,
  },
  {
    predicate: tag => tag.toLowerCase().includes("reddit"),
    Icon: FaReddit,
  },
  {
    predicate: tag => tag.toLowerCase().includes("discord"),
    Icon: FaDiscord,
  },
];

const TagList = ({ tags, links }) => {
  const tagsWithIcons = useMemo(
    () =>
      tags.map(tag => {
        const { Icon } =
          tagToIcon.find(({ predicate, Icon }) => predicate(tag)) || {};

        return { tag, Icon };
      }),
    [tags]
  );

  return (
    <div className={style.tags}>
      {tagsWithIcons.map(({ tag, Icon }, index) =>
        links[index] ? (
          <a
            className={!!Icon ? style.leftPadding : ""}
            key={tag}
            target="_blank"
            rel="nofollow noopener noreferrer"
            href={links[index]}
          >
            {!!Icon && <Icon size="28" />}
            {tag}
          </a>
        ) : (
          <span key={tag}>{tag}</span>
        )
      )}
    </div>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagList;
